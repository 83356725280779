import styled, { css } from 'styled-components';

import { ArrowBackIosRounded } from '@material-ui/icons';
import { COLORS } from 'helpers/enums/colors';
import { SIZES } from 'helpers/enums/sizes';

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0%;
  display: flex;
  gap: 30px;

  @media (max-width: ${SIZES.desktop}px) {
    flex-direction: column;
  }
`;

export const AvailableTitle = styled.div`
  color: ${COLORS.white};
  font-size: 20px;
  font-family: OpenSansBold;
  text-align: left;
  margin-bottom: 14px;
`;

export const PastTitle = styled(AvailableTitle)`
  color: ${COLORS.riverBed};
`;

export const Body = styled.div`
  color: ${COLORS.riverBed};
  font-size: 16px;
  font-family: 'OpenSans';
  margin-top: 70px;
  text-align: center;
`;

export const IconContainer = styled.div`
  position: absolute;
  right: 0;
  opacity: 0.1;

  @media (max-width: ${SIZES.mediumTablet}px) {
    display: none;
  }
`;

export const TrainingContainer = styled.div`
  margin: 5px 0 5px 40px;
  display: flex;
  justify-content: center;
  max-width: 250px;

  @media (max-width: ${SIZES.mediumTablet}px) {
    display: block;
    margin: 5px 0 5px;
  }
`;

export const SignContainer = styled.div`
  width: 100%;
  border: 2px solid ${COLORS.white};
  background: ${COLORS.grayCatskill};
  display: flex;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0px 4px 8px ${COLORS.stratos}20;
  z-index: 1;

  @media (max-width: ${SIZES.bigTablet}px) {
    width: 90%;
    margin: auto;
  }

  @media (max-width: ${SIZES.mediumTablet}px) {
    flex-direction: column;
    align-items: center;
    padding: 20px 0 0;
  }
`;

export const NoTrainingsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const NoTrainingsTitle = styled.div`
  font: 30px/1.2 'OpenSansBold';
  color: ${COLORS.riverBed};
  text-align: center;
  width: 80%;
  margin: 15px 0;
  z-index: 1;

  @media (max-width: ${SIZES.bigTablet}px) {
    font: 22px/1 'OpenSansBold';
    width: 95%;
  }
`;

export const NoTrainingsSubtitle = styled.div`
  font: 16px/1 'OpenSansSemiBold';
  color: ${COLORS.riverBed};
  text-align: center;
  width: 80%;
  z-index: 1;

  @media (max-width: ${SIZES.bigTablet}px) {
    font: 14px/1 'OpenSansSemiBold';
    width: 95%;
  }
`;

export const Image = styled.img`
  width: 25%;
  min-width: 200px;
  margin: 30px 30px 0 0;
  z-index: 1;

  @media (max-width: ${SIZES.bigTablet}px) {
    margin: 20px 0 0;
  }
`;

export const CarouselContainer = styled.div`
  width: 100%;
`;

export const ProgressContainer = styled.div`
  width: 184px;
  height: 469px;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  background: ${COLORS.riverBed};
  border-radius: 8px;
  margin-left: 25px;
  @media (max-width: ${SIZES.desktop}px) {
    margin: auto;
    box-sizing: border-box;
    width: 90%;
    height: auto;
  }
`;

export const ProgressTitle = styled.div`
  padding: 20px 14px 12px;
  text-align: center;
  font-family: OpenSansBold;
  font-size: 18px;
  line-height: 24px;
  max-width: 100%;
  color: ${COLORS.white};
`;

export const ProgressDescription = styled.div`
  text-align: center;
  font-family: OpenSans;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.white};
  margin-bottom: 20px;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (max-width: ${SIZES.desktop}px) {
    order: 2;
    width: 100%;
    gap: 0px;
    align-items: center;
  }
`;

const CapsuleContainer = styled.div`
  height: auto;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  width: 932px;
  @media (max-width: ${SIZES.desktop}px) {
    width: 100%;
    padding: 20px;
    border-radius: 0;
  }
`;

export const AvailableTrainingsContainer = styled(CapsuleContainer)`
  background: ${COLORS.riverBed};
`;

export const PastTrainingsContainer = styled(CapsuleContainer)`
  background: ${COLORS.lightblueZumthor};
  height: 469px;
`;

export const SelectVersionContainer = styled.div`
  display: flex;
  margin-bottom: 14px;
  gap: 18px;
`;

export const SelectVersionButton = styled.div<ICapsuleViewStyles>`
  width: 92px;
  height: 24px;
  border-radius: 4px;
  background: ${(props: ICapsuleViewStyles) =>
    props.isSelectedVersion ? COLORS.blueResolution : COLORS.doveGray};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.white};
  font-size: 14px;
  font-family: OpenSansBold;
  cursor: pointer;

  :hover {
    ${(props: ICapsuleViewStyles) =>
      !props.isSelectedVersion &&
      css`
        background: ${COLORS.charcoalGray};
      `};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 20px 20px 0;
  font-family: 'OpenSansSemiBold';
  font-size: 16px;
  width: fit-content;
  gap: 8.5px;

  @media (max-width: ${SIZES.desktop}px) {
    margin-left: 25px;
  }
`;

export const DefaultText = styled.div`
  color: ${COLORS.mistyQuartz};
  @media (max-width: ${SIZES.desktop}px) {
    display: none;
  }
`;

export const CapsuleTitleText = styled.div`
  color: ${COLORS.riverBed};
`;

export const GrayLine = styled.div<ICapsuleViewStyles>`
  border: 1px solid ${COLORS.mistyQuartz};
  width: 0px;
  height: 15px;

  @media (max-width: ${SIZES.desktop}px) {
    ${(props) =>
      !props.isLast &&
      css`
        display: none;
      `}
  }
`;

export const BackButton = styled(ArrowBackIosRounded)`
  width: 7px;
  cursor: pointer;

  .MuiSvgIcon-root {
    color: ${COLORS.riverBed};
  }
`;

export const ViewContainer = styled.div`
  width: 100%;
  height: 90%;
  box-sizing: border-box;
  padding: 0 35px;
  max-width: 1296px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${SIZES.desktop}px) {
    padding: 0;
  }
`;

interface ICapsuleViewStyles {
  isSelectedVersion?: boolean;
  isLast?: boolean;
}
